import { Expense } from './expense'

const expenses: Expense[] = [
  {
    name: 'IELTS General',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 200, max: 500 },
    priceRangeCouple: { min: 400, max: 1000 },
    priceRangeCoupleOneKid: { min: 400, max: 1000 },
    time: 'plural.month',
    timeRange: '1 - 2',
    result: 'expenses.results.paper',
    step: 'Eligibility',
    linkTitle: 'IELTS: International English Language Testing System',
    link: 'https://www.ielts.org',
  },
  {
    name: 'CELPIP General',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 200, max: 450 },
    priceRangeCouple: { min: 400, max: 900 },
    priceRangeCoupleOneKid: { min: 400, max: 900 },
    time: 'plural.month',
    timeRange: '1 - 2',
    result: 'expenses.results.paper',
    step: 'Eligibility',
    linkTitle: 'CELPIP: Canadian English Language Proficiency Index Program',
    link: 'https://www.celpip.ca',
    isOptional: true,
    optionalMax: true,
  },
  {
    name: 'TEF',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 330, max: 550 },
    priceRangeCouple: { min: 660, max: 1100 },
    priceRangeCoupleOneKid: { min: 660, max: 1100 },
    time: 'plural.month',
    timeRange: '1 - 2',
    result: 'expenses.results.paper',
    step: 'Eligibility',
    linkTitle: 'TEF Canada: Test d’évaluation de français',
    link:
      'https://www.lefrancaisdesaffaires.fr/en/tests-diplomas/test-for-evaluating-french-tef/tef-canada',
    isOptional: true,
  },
  {
    name: 'TCF',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 400, max: 550 },
    priceRangeCouple: { min: 800, max: 1100 },
    priceRangeCoupleOneKid: { min: 800, max: 1100 },
    time: 'plural.month',
    timeRange: '1 - 2',
    result: 'expenses.results.paper',
    step: 'Eligibility',
    linkTitle: 'TCF Canada: Test de connaissance du français',
    link: 'http://www.ciep.fr/tcf-canada',
    isOptional: true,
    optionalMax: true,
  },
  {
    name: 'Educational Credential Assesment (ECA)',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 260, max: 350 },
    priceRangeCouple: { min: 520, max: 700 },
    priceRangeCoupleOneKid: { min: 520, max: 700 },
    time: 'plural.month',
    timeRange: '3 - 4',
    result: 'expenses.results.paper',
    step: 'Eligibility',
    linkTitle: 'Designated Organizations',
    link:
      'https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/education-assessed/how.html#designated',
  },
  {
    name: 'Provincial Nomination Program',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 0, max: 1500 },
    priceRangeCouple: { min: 0, max: 1500 },
    priceRangeCoupleOneKid: { min: 0, max: 1500 },
    time: 'plural.month',
    timeRange: '6 - 12',
    result: 'expenses.results.email',
    step: 'Eligibility',
    linkTitle: 'PNP',
    link:
      'https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/provincial-nominees/works.html',
  },
  {
    name: 'Medical Exam',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 200, max: 750 },
    priceRangeCouple: { min: 400, max: 1500 },
    priceRangeCoupleOneKid: { min: 600, max: 2250 },
    time: 'plural.month',
    timeRange: '1 - 2',
    result: 'expenses.results.email',
    step: 'ITA',
    linkTitle: 'Medical exam for permanent resident applicants',
    link:
      'https://www.canada.ca/en/immigration-refugees-citizenship/services/application/medical-police/medical-exams/requirements-permanent-residents.html',
  },
  {
    name: 'Police certificates',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 0, max: 100 },
    priceRangeCouple: { min: 0, max: 200 },
    priceRangeCoupleOneKid: { min: 0, max: 300 },
    unboundedPrice: true,
    time: 'plural.month',
    timeRange: '1 - 2',
    result: 'expenses.results.paper',
    step: 'ITA',
    linkTitle: 'Police certificates for Express Entry applications',
    link:
      'https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/apply-permanent-residence/police-certificates.html',
  },
  {
    name: 'Translation of documents',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 0, max: 100 },
    priceRangeCouple: { min: 0, max: 200 },
    priceRangeCoupleOneKid: { min: 0, max: 300 },
    unboundedPrice: true,
    time: 'plural.week',
    timeRange: '1 - 2',
    result: 'expenses.results.paper',
    step: 'ITA',
    linkTitle: 'Translation of documents',
    link:
      'https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/permanent-residence/express-entry/applications-received-on-after-january-1-2016-completeness-check.html',
  },
  {
    name: 'Application processing fee',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 825 },
    priceRangeCouple: { min: 1650 },
    priceRangeCoupleOneKid: { min: 1875 },
    time: 'plural.month',
    timeCount: 3,
    result: 'expenses.results.email',
    step: 'ITA',
    linkTitle: 'Fees list',
    link: 'https://www.cic.gc.ca/english/information/fees/fees.asp',
  },
  {
    name: 'Right of Permanent Residence Fee',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 500 },
    priceRangeCouple: { min: 1000 },
    priceRangeCoupleOneKid: { min: 1000 },
    time: 'plural.month',
    timeCount: 1,
    result: 'expenses.results.email',
    step: 'ITA',
    linkTitle: 'Fees list',
    link: 'https://www.cic.gc.ca/english/information/fees/fees.asp',
  },
  // {
  //   name: 'Dependent child fee',
  //   currencyCode: 'CAD',
  //   priceRange: [225],
  //   time: 'plural.month',
  //   timeCount: 3,
  //   result: 'expenses.results.email',
  //   step: 'ITA',
  //   linkTitle: 'Fees list',
  //   link: 'https://www.cic.gc.ca/english/information/fees/fees.asp',
  // },
  {
    name: 'Biometrics',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 85 },
    priceRangeCouple: { min: 170 },
    priceRangeCoupleOneKid: { min: 170 },
    time: 'plural.month',
    timeCount: 1,
    result: 'expenses.results.paper',
    step: 'PR',
    linkTitle: 'Fees list',
    link: 'https://www.cic.gc.ca/english/information/fees/fees.asp',
  },
  {
    name: 'Passport visa fee',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 100 },
    priceRangeCouple: { min: 200 },
    priceRangeCoupleOneKid: { min: 300 },
    time: 'plural.month',
    timeRange: '1 - 2',
    result: 'expenses.results.paper',
    step: 'PR',
    linkTitle: 'Fees list',
    link: 'https://www.cic.gc.ca/english/information/fees/fees.asp',
  },
  {
    name: 'Passport request',
    currencyCode: 'CAD',
    priceRangeSingle: { min: 20, max: 100 },
    priceRangeCouple: { min: 40, max: 200 },
    priceRangeCoupleOneKid: { min: 60, max: 300 },
    time: 'plural.month',
    timeRange: '1 - 2',
    result: 'expenses.results.paper',
    step: 'PR',
    linkTitle: 'VFS Global',
    link: 'https://www.vfsglobal.com',
  },
]

export default expenses
