
import Vue from 'vue'
import Component from 'vue-class-component'
import UiUtils from '@/ui/util/ui-utils'
import CurrencyRatesRepository from '@/data/repository/currency-rates.repository'

import CurrencyCode from './currency-code'
import CurrencySelector from './CurrencySelector.vue'
import { CurrencyRates } from 'shared-entities'
import LocalStorage from '@/data/util/local-storage'
import FormattingUtils from '@/common/util/formatting-utils'

import expenseList from './expense-list'
import { Expense, PriceRange } from './expense'

const LS_KEY_CURRENCY_CODE = 'app_selectedCurrencyCode'
const DEFAULT_CURRENCY_CODE: CurrencyCode = 'CAD'

@Component({
  head: {
    title: UiUtils.translateTitle('label.expenses'),
  },
  components: {
    CurrencySelector,
  },
})
export default class ExpensesScreen extends Vue {
  expenses: Expense[] = expenseList

  selectedCurrencyCode: CurrencyCode = DEFAULT_CURRENCY_CODE

  isCurrencyRatesLoading = false
  private currencyRates: CurrencyRates | null = null

  get isCurrencySelected(): boolean {
    return this.selectedCurrencyCode !== DEFAULT_CURRENCY_CODE
  }

  mounted() {
    const savedCurrencyCode = LocalStorage.getItem(LS_KEY_CURRENCY_CODE)
    if (savedCurrencyCode) {
      this.selectedCurrencyCode = savedCurrencyCode
    }

    this.initCurrencyRates()
  }

  private async initCurrencyRates() {
    this.isCurrencyRatesLoading = true
    try {
      const result = await CurrencyRatesRepository.getCurrencyRates()
      if (result.isSuccessful) {
        this.currencyRates = result.value
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isCurrencyRatesLoading = false
    }
  }

  onCurrencySelected(currencyCode: CurrencyCode) {
    this.selectedCurrencyCode = currencyCode
    LocalStorage.putItem(LS_KEY_CURRENCY_CODE, currencyCode)
  }

  getSinglePriceRange(expense: Expense): string {
    return this.getPriceRangeLabel(expense, expense.priceRangeSingle)
  }

  getCouplePriceRange(expense: Expense): string {
    return this.getPriceRangeLabel(expense, expense.priceRangeCouple)
  }

  getFamilyPriceRange(expense: Expense): string {
    return this.getPriceRangeLabel(expense, expense.priceRangeCoupleOneKid)
  }

  private getPriceRangeLabel(expense: Expense, priceRange: PriceRange): string {
    const currencyCode =
      this.selectedCurrencyCode && this.currencyRates
        ? this.selectedCurrencyCode
        : expense.currencyCode

    const convertedRange = this.convertPriceRange(expense.currencyCode, priceRange)
    return this.formatPriceRange(currencyCode, expense.unboundedPrice, convertedRange)
  }

  private formatPriceRange(
    currencyCode: CurrencyCode,
    unboundedPrice: boolean | undefined,
    priceRange: PriceRange
  ): string {
    let priceLabel
    if (priceRange.max) {
      priceLabel =
        FormattingUtils.formatNumeric(priceRange.min) +
        ' - ' +
        FormattingUtils.formatNumeric(priceRange.max)
    } else {
      priceLabel = FormattingUtils.formatNumeric(priceRange.min)
    }

    return `${priceLabel}${unboundedPrice ? '+' : ''} <span class="currency">${currencyCode}</span>`
  }

  // TODO: left for later
  filterExpensesByStep(expenses: Expense[], step: string): Expense[] {
    return expenses.filter(expense => {
      return expense.step == step
    })
  }

  private convertPriceRange(sourceCurrency: CurrencyCode, priceRange: PriceRange): PriceRange {
    if (this.selectedCurrencyCode && this.currencyRates) {
      if (this.selectedCurrencyCode === sourceCurrency) {
        return priceRange
      } else {
        const result: PriceRange = {
          min: this.convertPrice(priceRange.min, sourceCurrency, this.selectedCurrencyCode),
        }

        if (priceRange.max) {
          result.max = this.convertPrice(priceRange.max, sourceCurrency, this.selectedCurrencyCode)
        }

        return result
      }
    } else {
      return priceRange
    }
  }

  private convertPrice(
    price: number,
    sourceCurrency: CurrencyCode,
    targetCurrency: CurrencyCode
  ): number {
    const currencyRates = this.currencyRates!
    const targetCurrencyRate = currencyRates.rates[targetCurrency]
    const priceInBase = price / currencyRates.rates[sourceCurrency]

    if (targetCurrencyRate > 10) {
      return Math.ceil((priceInBase * targetCurrencyRate) / 100) * 100
    } else {
      return Math.ceil((priceInBase * targetCurrencyRate) / 10) * 10
    }
  }

  get singleTotal(): string {
    return this.getTotal('priceRangeSingle')
  }

  get coupleTotal(): string {
    return this.getTotal('priceRangeCouple')
  }

  get familyTotal(): string {
    return this.getTotal('priceRangeCoupleOneKid')
  }

  private getTotal(
    priceRangeKey: 'priceRangeSingle' | 'priceRangeCouple' | 'priceRangeCoupleOneKid'
  ): string {
    const priceRange = this.sumPriceRanges(
      this.expenses
        .map(
          (expense: Expense): PriceRange => {
            const priceRange = expense[priceRangeKey]
            const result: PriceRange = {
              min: expense.isOptional ? 0 : priceRange.min,
            }

            if (typeof priceRange.max !== 'undefined' && !expense.optionalMax) {
              result.max = priceRange.max
            }

            return result
          }
        )
        .map(
          (priceRange: PriceRange): PriceRange =>
            this.convertPriceRange(DEFAULT_CURRENCY_CODE, priceRange)
        )
    )

    return this.formatPriceRange(this.selectedCurrencyCode, true, priceRange)
  }

  private sumPriceRanges(priceRanges: PriceRange[]): PriceRange {
    return priceRanges.reduce(
      (total: PriceRange, priceRange: PriceRange): PriceRange => {
        const rightBoundary =
          typeof priceRange.max === 'undefined' ? priceRange.min : priceRange.max
        if (total) {
          return {
            min: total.min + priceRange.min,
            max: total.max! + rightBoundary,
          }
        } else {
          return { min: priceRange.min, max: rightBoundary }
        }
      }
    )
  }
}
