
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import { Prop } from 'vue-property-decorator'
import CurrencyCode from './currency-code'

interface Currency extends SelectOption {
  id: CurrencyCode
  title: string
  flagIcon: string
}

@Component
export default class CurrencySelector extends Vue {
  @Prop({ type: String, required: true })
  value!: string | null

  currencies: Currency[] = [
    {
      id: 'CAD',
      title: 'Canadian Dollar',
      flagIcon: require('@/assets/flags/canada.png'),
    },
    {
      id: 'USD',
      title: 'US Dollar',
      flagIcon: require('@/assets/flags/usa.png'),
    },
    {
      id: 'EUR',
      title: 'Euro',
      flagIcon: require('@/assets/flags/eu.png'),
    },
    {
      id: 'INR',
      title: 'Indian Rupee',
      flagIcon: require('@/assets/flags/india.png'),
    },
    {
      id: 'PHP',
      title: 'Philippine Peso',
      flagIcon: require('@/assets/flags/philippines.png'),
    },
  ]

  onCurrencySelected(currencyCode: string) {
    this.$emit('input', currencyCode)
  }

  showDisclaimer() {
    this.$dialog.alert({
      title: 'Disclaimer',
      message:
        'These figures are given solely as an indication. Some of them may vary ' +
        'between countries. The base currency is the Canadian dollar (CAD), and values in other ' +
        'currencies are converted from CAD and rounded up. <br><br>Exchange rates are ' +
        'approximiate and may be delayed. We do not verify them and disclaim any obligation ' +
        'to do so. Actual rates may differ.<br><br>' +
        'Exchange rates are published by the <a href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html" target="_blank" rel="noopener">European Central Bank</a>.',
    })
  }
}
