import { OperationResult, OperationSuccess, OperationError } from '../api/operation-result'
import { CurrencyRates, Collection, DocumentName } from 'shared-entities'
import LocalStorage from '../util/local-storage'
import asyncFirestore from '../firebase/async-firestore'
import FirestoreUtils from '../firebase/firestore-utils'
import http from '../api/http'
import ResponseParser from '../api/response-parser'

// Request fresh data from the HTTP API if 12 hours pass after the previous refresh.
const REFRESH_THRESHOLD_MS = 12 * 60 * 60 * 1000
// In-memory cache will be used for 10 minutes.
const CACHE_VALIDITY_MS = 10 * 60 * 1000

const LS_KEY_LAST_CURRENCY_RATES_REFRESH_TIME = 'app_lastCurrencyRatesRefreshTime'

export default class CurrencyRatesRepository {
  private static cachedCurrencyRates: CurrencyRates | null = null
  private static cacheTimestamp: number | null = null

  static async getCurrencyRates(): Promise<OperationResult<CurrencyRates>> {
    const now = Date.now()
    if (
      this.cachedCurrencyRates &&
      this.cacheTimestamp &&
      now - this.cacheTimestamp < CACHE_VALIDITY_MS
    ) {
      return new OperationSuccess(this.cachedCurrencyRates)
    }

    // Every time we refresh the currency rates using the HTTP API, we
    // save the timestamp in the local storage. Later when this method is called
    // again we check if the data needs to be refreshed. If not, then Firestore is accessed
    // directly. This is done to save Firebase Functions quota, which is more limited
    // than direct Firestore reads.
    const lastRefreshTime = LocalStorage.getItem(LS_KEY_LAST_CURRENCY_RATES_REFRESH_TIME)
    if (
      typeof lastRefreshTime === 'number' &&
      Date.now() - lastRefreshTime < REFRESH_THRESHOLD_MS
    ) {
      const firestore = await asyncFirestore()
      const currencyRatesDoc = await firestore
        .collection(Collection.CURRENCY_RATES)
        .doc(DocumentName.CURRENCY_RATES)
        .get()

      if (currencyRatesDoc.exists) {
        const currencyRates = FirestoreUtils.convertTimestamps(
          currencyRatesDoc.data() as CurrencyRates
        )
        this.cachedCurrencyRates = currencyRates
        this.cacheTimestamp = Date.now()
        return new OperationSuccess(currencyRates)
      } else {
        return this.refreshCurrencyRates()
      }
    } else {
      return this.refreshCurrencyRates()
    }
  }

  private static async refreshCurrencyRates(): Promise<OperationResult<CurrencyRates>> {
    try {
      const response = await http.get('/misc/currency-rates')
      const result = ResponseParser.parse<CurrencyRates>(response)
      if (result.isSuccessful) {
        LocalStorage.putItem(LS_KEY_LAST_CURRENCY_RATES_REFRESH_TIME, Date.now())
        this.cachedCurrencyRates = result.value
        this.cacheTimestamp = Date.now()
      }
      return result
    } catch (error) {
      return OperationError.fromError(error)
    }
  }
}
